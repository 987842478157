.logs-section {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    margin-bottom: 2rem;
    overflow-x: auto;
}

.section-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: white;
}

.conversation-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.conversation-table th {
    border-bottom: 1px solid #f0f0f0;
    padding: 0.5rem;
    text-align: left;
}

.conversation-table td {
    padding: 0.5rem;
}

.conversation-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.conversation-row:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.conversation-row.selected {
    background-color: rgba(255, 255, 255, 0.1);
}

.recording-link {
    color: #4CAF50;
    text-decoration: none;
}

.error-message {
    background: rgba(255, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    color: white;
}

.messages-section {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
}

.summary-section {
    margin-bottom: 2rem;
}

.summary-title {
    margin-bottom: 0.5rem;
}

.summary-content {
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 8px;
}

.messages-title {
    margin-bottom: 1rem;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.message {
    padding: 1rem;
    border-radius: 8px;
    max-width: 80%;
}

.message.assistant {
    background: rgba(76, 175, 80, 0.1);
    align-self: flex-start;
}

.message.user {
    background: rgba(255, 255, 255, 0.05);
    align-self: flex-end;
}

.message-timestamp {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: #aaa;
}

@media (max-width: 768px) {
    .logs-section {
        padding: 1rem;
    }

    .conversation-table {
        min-width: auto;
        display: block;
    }

    .conversation-table thead {
        display: none;
    }

    .conversation-table tbody {
        display: block;
    }

    .conversation-table tr {
        display: block;
        margin-bottom: 1rem;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        padding: 1rem;
    }

    .conversation-table td {
        display: block;
        padding: 0.25rem 0;
        text-align: left;
    }

    .conversation-table td::before {
        content: attr(data-label);
        font-weight: bold;
        display: inline-block;
        width: 120px;
    }

    .message {
        max-width: 90%;
    }

    .messages-section {
        padding: 1rem;
    }
}
