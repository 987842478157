.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.coming-soon-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.coming-soon-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.coming-soon-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}
