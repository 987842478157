.tools-section {
  background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
  border-radius: var(--border-radius-lg);
  padding: 1.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.section-description {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.5rem;
}

/* Save Message Styles */
.save-message {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.save-message.success {
  background: rgba(34, 205, 148, 0.2);
  border: 1px solid rgba(34, 205, 148, 0.3);
  color: #22cd94;
}

.save-message.error {
  background: rgba(220, 53, 69, 0.2);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Grid Layout for Tool Form */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}

.input-group {
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

.input-group input,
.input-group textarea,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--border-radius-md);
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box;
  height: 2.75rem;
}

.input-group textarea {
  height: auto;
  min-height: 2.75rem;
}

/* Style for select dropdown options */
.input-group select option {
  background-color: #2a2a2a;
  color: white;
  padding: 0.75rem;
}

/* Special styling for the parameters textarea */
.parameters-textarea {
  height: auto !important;
  min-height: 200px !important;
  line-height: 1.5;
  resize: vertical;
  font-family: monospace;
  white-space: pre-wrap;
}

.input-group input:focus,
.input-group textarea:focus,
.input-group select:focus {
  background: rgba(255, 255, 255, 0.12);
  border-color: var(--accent);
  outline: none;
  box-shadow: 0 0 0 2px rgba(34, 205, 148, 0.2);
}

.input-group input::placeholder,
.input-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Full width inputs */
.input-group.full-width {
  grid-column: 1 / -1;
}

/* Buttons */
.primary-button {
  background: var(--accent);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.primary-button:hover {
  background: #1aa678;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(34, 205, 148, 0.4);
}

.secondary-button {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.secondary-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.button-container {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Tool List Styles */
.tools-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.tool-card {
  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.15);
  overflow: hidden;
  transition: all 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.tool-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tool-title-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.tool-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}

.tool-badge {
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-sm);
  text-transform: uppercase;
}

.tool-badge.custom {
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  border: 1px solid rgba(255, 193, 7, 0.3);
}

.tool-badge.builtin {
  background: rgba(13, 110, 253, 0.2);
  color: #0d6efd;
  border: 1px solid rgba(13, 110, 253, 0.3);
}

.tool-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
  transition: all 0.3s ease;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.12);
  color: var(--accent);
}

.icon-button.edit:hover {
  color: var(--primary);
}

.icon-button.delete:hover {
  color: #dc3545;
}

.tool-body {
  padding: 1rem;
}

.tool-description {
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.tool-function,
.tool-server {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.text-muted {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  font-family: monospace;
}

.parameters-container {
  margin-top: 1rem;
}

.parameters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.parameters-title {
  font-size: 0.9rem;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
}

.parameters-content {
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-sm);
  padding: 0.75rem;
  font-family: monospace;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.9);
  white-space: pre-wrap;
  overflow-x: auto;
  max-height: 200px;
  overflow-y: auto;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
}

.add-tool-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: var(--accent);
  color: white;
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
}

.add-tool-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.add-tool-button svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Parameter Editor Styles */
.parameter-editor {
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-md);
  padding: 1rem;
  margin-bottom: 1rem;
}

.parameter-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.parameter-editor-header h4 {
  margin: 0;
  color: white;
}

.add-property-button {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.add-property-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

.empty-properties {
  text-align: center;
  padding: 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  background: rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-sm);
}

.properties-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.property-item {
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.property-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.property-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.property-name {
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.property-type {
  font-size: 0.75rem;
  color: var(--accent);
  background: rgba(34, 205, 148, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-sm);
}

.property-details {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.property-field {
  margin-bottom: 0.75rem;
}

.property-field label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
}

.property-field input,
.property-field select {
  width: 100%;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--border-radius-sm);
  color: white;
  font-size: 0.9rem;
}

.property-field.checkbox {
  display: flex;
  align-items: center;
}

.property-field.checkbox label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0;
  cursor: pointer;
}

.property-field.checkbox input {
  width: auto;
  margin: 0;
}

.remove-property-button {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  border: 1px solid rgba(220, 53, 69, 0.2);
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.remove-property-button:hover {
  background: rgba(220, 53, 69, 0.2);
}

/* Editor Toggle Styles */
.editor-toggle {
  display: flex;
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-md);
  padding: 0.25rem;
}

.editor-toggle-option {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.7);
}

.editor-toggle-option.active {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 500;
}

/* Custom Tool Settings */
.tool-type-selector {
  margin-bottom: 1.5rem;
}

.tool-type-option {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-md);
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tool-type-option:hover {
  background: rgba(0, 0, 0, 0.3);
}

.tool-type-option.selected {
  background: rgba(34, 205, 148, 0.1);
  border: 1px solid rgba(34, 205, 148, 0.3);
}

.tool-type-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.tool-type-option.disabled:hover {
  background: rgba(0, 0, 0, 0.2);
}

.admin-only-badge {
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-sm);
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
  margin-left: 0.5rem;
  text-transform: uppercase;
}

.tool-type-option input[type="radio"] {
  margin: 0;
}

.tool-type-option-content {
  flex: 1;
}

.tool-type-option-title {
  font-weight: 500;
  color: white;
  margin-bottom: 0.25rem;
}

.tool-type-option-description {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
}

.server-settings {
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-md);
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.server-settings h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
}

.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.toggle-switch label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.toggle-switch-control {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch-control input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .4s;
  border-radius: 20px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--accent);
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

/* HTTP Headers */
.http-headers {
  margin-top: 1.5rem;
}

.header-row {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  align-items: center;
}

.header-row .input-group {
  margin-bottom: 0;
  flex: 1;
}

.remove-header-button {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  border: 1px solid rgba(220, 53, 69, 0.2);
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-header-button:hover {
  background: rgba(220, 53, 69, 0.2);
}

.empty-headers {
  text-align: center;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.6);
  background: rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-sm);
  margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr !important; /* Override any inline styles */
  }
  
  .tools-list {
    grid-template-columns: 1fr;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
}
