.account-section {
  background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
  border-radius: var(--border-radius-lg);
  padding: 1.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.section-description {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.5rem;
}

/* Save Message Styles */
.save-message {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.save-message.success {
  background: rgba(34, 205, 148, 0.2);
  border: 1px solid rgba(34, 205, 148, 0.3);
  color: #22cd94;
}

.save-message.error {
  background: rgba(220, 53, 69, 0.2);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Grid Layout for Profile and Assistant Sections */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}

.input-group {
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

.input-group input,
.input-group textarea,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--border-radius-md);
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box;
  height: 2.75rem;
}

/* Toggle Button Styles */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  min-height: 32px; /* Fixed height to prevent movement */
}

.toggle-container label {
  margin: 0;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toggle-button {
  background: transparent;
  border: 2px solid #666;
  border-radius: 20px;
  width: 48px;
  height: 24px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  flex-shrink: 0;
  margin-left: auto; /* Push to the right */
}

.toggle-button.active {
  border-color: var(--accent);
}

.toggle-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border-color: #444;
}

.toggle-button:disabled .toggle-slider {
  background: #444;
}

.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #666;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.toggle-button.active .toggle-slider {
  left: 26px;
  background: var(--accent);
}

/* Style for select dropdown options */
.input-group select option {
  background-color: #2a2a2a;
  color: white;
  padding: 0.75rem;
}

/* Special styling for the prompt textarea */
.prompt-textarea {
  height: auto !important;
  min-height: 400px !important; /* Approximately 20 lines at default font size */
  line-height: 1.5;
  resize: vertical;
  font-family: inherit;
  white-space: pre-wrap;
}

.input-group input:focus,
.input-group textarea:focus,
.input-group select:focus {
  background: rgba(255, 255, 255, 0.12);
  border-color: var(--accent);
  outline: none;
  box-shadow: 0 0 0 2px rgba(34, 205, 148, 0.2);
}

.input-group input::placeholder,
.input-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Full width inputs */
.input-group.full-width {
  grid-column: 1 / -1;
}

/* Buttons */
.primary-button {
  background: var(--accent);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.primary-button:hover {
  background: #1aa678;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(34, 205, 148, 0.4);
}

/* Rest of the styles remain unchanged */
/* Documents Section Specific Styles */
.documents-container {
  margin-top: 1rem;
}

.upload-container {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: var(--border-radius-md);
  background: rgba(255, 255, 255, 0.08);
}

.file-input {
  margin-bottom: 1rem;
  color: #fff;
}

.upload-button {
  background: var(--accent);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background: #1aa678;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(34, 205, 148, 0.4);
}

.upload-status {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: var(--border-radius-md);
}

.upload-status.success {
  background: rgba(34, 205, 148, 0.2);
  color: var(--accent);
}

.upload-status.error {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

.document-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.document-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.document-name {
  font-weight: 500;
  color: white;
}

.document-date {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.document-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
  transition: all 0.3s ease;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.12);
  color: var(--accent);
}

.document-content-container {
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.document-actions-bar {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.edit-button, .save-button {
  background: var(--primary);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.edit-button:hover, .save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(23, 133, 212, 0.4);
}

.save-button {
  background: var(--accent);
}

.save-button:hover {
  box-shadow: 0 4px 15px rgba(34, 205, 148, 0.4);
}

.save-status {
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-md);
  font-size: 0.875rem;
}

.save-status.success {
  background: rgba(34, 205, 148, 0.2);
  color: var(--accent);
}

.save-status.error {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

.document-editor {
  width: 100%;
  min-height: 300px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--border-radius-md);
  padding: 1rem;
  color: #fff;
  font-family: inherit;
  resize: vertical;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.document-editor:focus {
  background: rgba(255, 255, 255, 0.12);
  border-color: var(--accent);
  outline: none;
  box-shadow: 0 0 0 2px rgba(34, 205, 148, 0.2);
}

.document-content {
  background: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  border-radius: var(--border-radius-md);
  white-space: pre-wrap;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr !important; /* Override any inline styles */
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
}
