:root {
    --primary: #1785d4;
    --secondary: #361e7a;
    --accent: #22cd94;
    --border-radius-sm: 8px;
    --border-radius-md: 12px;
    --border-radius-lg: 16px;
    --sidebar-width: 250px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    min-height: 100vh;
    margin: 0;
    line-height: 1.6;
    background: linear-gradient(135deg, #1785d4 0%, #361e7a 100%);
}

#root {
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background: linear-gradient(135deg, #1785d4 0%, #361e7a 100%);
}

/* Page Container Styles */
.page-root {
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #1785d4 0%, #361e7a 100%);
    position: relative;
    overflow-x: hidden;
}

.dashboard-container {
    flex: 1;
    margin-left: var(--sidebar-width);
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 0;
}

.dashboard-header {
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-content {
    padding: 0 2rem 2rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.dashboard-title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    color: white;
}

.dashboard-subtitle {
    font-size: 1rem;
    color: #f0f0f0;
    margin: 0.5rem 0 0 0;
}

.loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .dashboard-container {
        margin-left: 0;
        padding-top: 70px;
        width: 100%;
    }

    .dashboard-content {
        padding: 0 1rem 1rem;
        width: 100%;
    }

    .dashboard-header {
        padding: 1rem;
    }
}

/* Auth pages (Login/Signup) styles */
.signup-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 2rem;
    gap: 4rem;
    color: white;
    overflow-y: auto;
}

.signup-container.login-page {
    justify-content: center;
    align-items: center;
}

.signup-container.login-page .form-column {
    flex: initial;
    width: 100%;
    max-width: 500px;
    padding: 0;
    justify-content: center;
    align-items: center;
}

/* Login/Signup Form Styles */
.content-column {
    flex: 1;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.tagline {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    color: var(--accent);
}

.hero-title {
    font-size: 4rem;
    font-weight: 800;
    margin: 1.5rem 0;
    line-height: 1.2;
    opacity: 0;
    animation: fadeIn 1s ease 0.3s forwards;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.highlight-ai-workforce {
    color: var(--accent);
    background: rgba(255, 255, 255, 0.08);
    padding: 0 0.2em;
    border-radius: var(--border-radius-sm);
    backdrop-filter: blur(8px);
}

.subtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    opacity: 0.9;
    color: #f0f0f0;
}

.feature-list {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.feature-list li {
    margin-bottom: 1.5rem;
    padding-left: 2.5rem;
    position: relative;
    font-size: 1.1rem;
    opacity: 0;
    display: flex;
    align-items: center;
    color: white;
}

.feature-list li svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--accent);
    width: 1.5rem;
    height: 1.5rem;
    stroke: var(--accent);
    stroke-width: 3;
    fill: none;
}

.feature-list li.animate-slide-in {
    animation: slideIn 0.5s ease forwards;
}

.form-column {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2rem;
}

.form-wrapper {
    max-width: 500px;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: var(--border-radius-lg);
    padding: 1rem;
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.form-logo-wrapper {
    width: 100%;
    background: white;
    border-radius: var(--border-radius-md);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.form-logo {
    width: 250px;
    height: 100px;
    object-fit: cover;
    display: block;
}

.assistant-form input {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: white;
    padding: 1rem;
    border-radius: var(--border-radius-md);
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    height: 2.75rem; /* Fixed height to prevent iOS styling issues */
}

.assistant-form input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.assistant-form input:focus {
    background: rgba(255, 255, 255, 0.12);
    border-color: var(--accent);
    outline: none;
    box-shadow: 0 0 0 2px rgba(34, 205, 148, 0.2);
}

.btn {
    border-radius: var(--border-radius-md);
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-color: var(--accent);
    color: white;
    border: none;
    position: relative;
    width: 100%;
    cursor: pointer;
}

.btn:hover {
    background-color: #1aa678;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(34, 205, 148, 0.4);
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Sidebar styles */
.sidebar {
    width: var(--sidebar-width);
    height: 100vh;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0;
    overflow-y: auto;
}

.sidebar-header {
    padding: 0 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-logo {
    max-width: 150px;
    height: auto;
}

.nav-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: #666;
    text-decoration: none;
    transition: all 0.3s ease;
    margin: 4px 0;
    border-radius: var(--border-radius-md);
    white-space: nowrap;
}

.nav-link svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    flex-shrink: 0;
}

.nav-link:hover {
    background: rgba(23, 133, 212, 0.1);
    color: var(--primary);
}

.nav-link.active {
    background: var(--primary);
    color: white;
}

.nav-link.active svg {
    color: white;
}

.logout-link {
    margin-top: auto;
    padding: 0 10px;
}

.logout-link .nav-link {
    color: #666;
    width: 100%;
}

.logout-link .nav-link:hover {
    color: #dc3545;
    background: rgba(220, 53, 69, 0.1);
}

/* Mobile Menu Styles */
.mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .mobile-menu {
        display: block;
    }

    .sidebar {
        display: none;
    }

    .signup-container {
        padding: 1rem;
        gap: 1rem;
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .signup-container.login-page {
        flex-direction: column;
        justify-content: center;
    }

    .signup-container.login-page .form-column {
        padding: 0;
        margin: auto 0;
    }

    .form-column {
        width: 100%;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 1rem;
    }

    .form-wrapper {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
    }

    .hero-title {
        font-size: 2.5rem;
    }

    .content-column {
        padding: 1rem;
    }
}

.mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 70px;
}

.mobile-logo {
    height: 80px;
    width: auto;
}

.mobile-menu-toggle {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu-toggle svg {
    width: 24px;
    height: 24px;
    color: #333;
}

.mobile-nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 0.5rem;
    border-top: 1px solid #eaeaea;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.mobile-nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #666;
    padding: 0.75rem 1rem;
    border-radius: var(--border-radius-md);
    transition: all 0.3s ease;
    font-size: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.mobile-nav-link svg {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    flex-shrink: 0;
}

.mobile-nav-link.active {
    background: var(--primary);
    color: white;
}

.mobile-nav-link:hover {
    background: rgba(23, 133, 212, 0.1);
    color: var(--primary);
}

.mobile-nav-link.logout-button {
    color: #dc3545;
    border-top: 1px solid #eaeaea;
    margin-top: 0.5rem;
    padding-top: 1rem;
}

.mobile-nav-link.logout-button:hover {
    background: rgba(220, 53, 69, 0.1);
    color: #dc3545;
}

/* Dashboard Cards */
.dashboard-card {
    background: white;
    border-radius: var(--border-radius-md);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Animations */
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.1);
    }
    70% {
        opacity: 0.9;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
