.billing-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.section-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    margin: 2rem 0 1rem;
}

.section-icon {
    color: var(--accent);
}

/* Credit Balance Card */
.credit-balance-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.card-header h3 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.1rem;
    color: white;
}

.balance-display {
    text-align: center;
    margin: 1.5rem 0;
}

.balance-amount {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--accent);
}

.balance-label {
    font-size: 0.9rem;
    color: white;
    margin-top: 0.5rem;
}

.balance-info {
    font-size: 0.9rem;
    color: white;
    text-align: center;
    margin-top: 1rem;
}

/* Payment Methods Card */
.payment-methods-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
}

.payment-methods-content {
    text-align: center;
    padding: 2rem 1rem;
}

/* Transaction History Card */
.transaction-history-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
    margin-bottom: 2rem;
}

.transaction-history-content {
    text-align: center;
    padding: 2rem 1rem;
}

/* Subscription Card */
.subscription-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
    margin-bottom: 2rem;
}

.subscription-status {
    text-align: center;
}

.subscription-status h3 {
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
}

.subscription-status p {
    margin: 0.5rem 0;
    color: white;
}

/* Usage History */
.usage-history {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

/* Desktop Table View */
.usage-table {
    width: 100%;
    min-width: 800px;
    color: white;
}

.usage-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 0.75rem 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.usage-row {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.usage-row:last-child {
    border-bottom: none;
}

.usage-row:hover {
    background: rgba(255, 255, 255, 0.02);
}

.usage-cell {
    display: flex;
    align-items: center;
    color: white;
}

.usage-header .usage-cell {
    color: white;
}

.usage-cell.cost {
    color: var(--accent);
}

/* Mobile Card View */
.usage-entry {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.usage-entry:last-child {
    border-bottom: none;
}

.usage-entry-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.usage-type {
    font-weight: bold;
    color: white;
}

.usage-cost {
    color: var(--accent);
}

.usage-details {
    font-size: 0.9rem;
    color: white;
}

.usage-details p {
    margin: 0.25rem 0;
}

/* Responsive Design */
.desktop-only {
    display: none;
}

.mobile-only {
    display: block;
}

@media (min-width: 769px) {
    .desktop-only {
        display: block;
        overflow-x: auto;
    }

    .mobile-only {
        display: none;
    }
}

@media (max-width: 768px) {
    .billing-layout {
        grid-template-columns: 1fr;
    }

    .balance-amount {
        font-size: 2rem;
    }
}

/* Placeholder Message */
.placeholder-message {
    color: white;
    font-size: 0.95rem;
    line-height: 1.5;
    margin: 0;
}

/* Add-ons Section */
.addons-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.addon-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    color: white;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.addon-card.subscribed {
    background: rgba(var(--accent-rgb), 0.15);
    border: 1px solid rgba(var(--accent-rgb), 0.3);
}

.addon-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.addon-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: white;
}

.subscription-badge {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background: var(--accent);
    color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: bold;
}

.addon-description {
    flex-grow: 1;
    margin-bottom: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.95rem;
    line-height: 1.5;
}

.addon-price {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--accent);
    margin-bottom: 1rem;
}

.addon-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantity-selector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.quantity-btn {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s;
}

.quantity-btn:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.2);
}

.quantity-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.quantity-value {
    min-width: 30px;
    text-align: center;
}

.quantity-info {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
}

.subscribe-btn, .unsubscribe-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
}

.subscribe-btn {
    background: var(--accent);
    color: black;
}

.subscribe-btn:hover:not(:disabled) {
    background: var(--accent-hover);
}

.unsubscribe-btn {
    background: rgba(229, 62, 62, 0.1);
    color: #e53e3e;
    border: 1px solid rgba(229, 62, 62, 0.3);
}

.unsubscribe-btn:hover:not(:disabled) {
    background: rgba(229, 62, 62, 0.2);
}

.subscribe-btn:disabled, .unsubscribe-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.no-addons-message {
    grid-column: 1 / -1;
    text-align: center;
    padding: 2rem;
    color: white;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
}

.loading-container {
    grid-column: 1 / -1;
    text-align: center;
    padding: 2rem;
    color: white;
}

@media (max-width: 768px) {
    .addons-container {
        grid-template-columns: 1fr;
    }
}

/* Error Message */
.error-message {
    background: rgba(229, 62, 62, 0.1);
    border: 1px solid rgba(229, 62, 62, 0.3);
    color: #e53e3e;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    text-align: center;
}

/* Loading Spinner */
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
    margin-right: 8px;
}
