.subscription-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    color: white;
}

.subscription-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2rem;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.logo {
    max-width: 200px;
    height: auto;
}

.plan-options {
    display: flex;
    gap: 2rem;
    margin: 2rem 0;
    flex-wrap: wrap;
    justify-content: center;
}

.plan-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 2rem;
    flex: 1;
    min-width: 280px;
    max-width: 350px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.plan-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
}

.plan-card.selected {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transform: translateY(-5px);
}

.plan-card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.plan-card .price {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
    color: #4CAF50;
}

.plan-card .monthly-equivalent {
    font-size: 1.1rem;
    color: #4CAF50;
    margin: 0.5rem 0;
}

.plan-card .savings {
    display: inline-block;
    background: rgba(76, 175, 80, 0.2);
    color: #4CAF50;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    margin: 0.5rem 0;
}

.plan-card ul {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
}

.plan-card ul li {
    margin: 0.8rem 0;
    display: flex;
    align-items: center;
}

.plan-card ul li:before {
    content: "✓";
    color: #4CAF50;
    margin-right: 0.5rem;
}

.payment-form {
    margin-top: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
}

.form-group input {
    width: 100%;
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 1rem;
}

.form-group input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
}

.form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.form-row .form-group {
    flex: 1;
    margin-bottom: 0;
}

.submit-button {
    width: 100%;
    padding: 1rem;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-button:hover {
    background: #45a049;
}

.submit-button:disabled {
    background: #666;
    cursor: not-allowed;
}

.terms {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.debug-panel {
    position: fixed !important;
    top: 20px !important;
    right: 20px !important;
    background: rgba(0, 0, 0, 0.9) !important;
    color: #00ff00 !important;
    padding: 20px !important;
    border-radius: 8px !important;
    max-width: 400px !important;
    max-height: 80vh !important;
    overflow-y: auto !important;
    z-index: 9999 !important;
    border: 1px solid #333 !important;
    font-family: monospace !important;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.2) !important;
}

.debug-panel h3 {
    color: #00ff00 !important;
    margin-top: 0 !important;
    border-bottom: 1px solid #333 !important;
    padding-bottom: 10px !important;
}

.debug-panel pre {
    white-space: pre-wrap !important;
    font-size: 12px !important;
    color: #00ff00 !important;
    background: rgba(0, 0, 0, 0.5) !important;
    padding: 10px !important;
    border-radius: 4px !important;
}

.debug-panel button {
    margin-top: 10px !important;
    padding: 5px 10px !important;
    background: #333 !important;
    border: 1px solid #00ff00 !important;
    color: #00ff00 !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-family: monospace !important;
}

.debug-panel button:hover {
    background: #444 !important;
}
